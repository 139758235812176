<template>
  <v-hover v-slot="{hover}">
    <v-list-item :title="title(task)" class="rounded item-task" dense>

      <v-list-item-action class="my-2">
        <v-checkbox v-model="task.done" @change="update" dense :color="progression == 100 ? 'success' : 'primary'"></v-checkbox>
      </v-list-item-action>
      <template v-if="!edit">
        <v-list-item-content>

          <v-list-item-subtitle :class="{'line-through': task.done, 'grey--text': task.done}">
            {{ task.title }}
          </v-list-item-subtitle>
          <v-list-item-subtitle :class="{'line-through': task.done, 'grey--text': task.done}">
            {{ task.text }}
          </v-list-item-subtitle>

        </v-list-item-content>
        <v-list-item-action class="d-flex flex-row align-center my-2">
          <v-btn icon @click="edit = true">
            <v-icon v-show="hover">mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn icon @click="$emit('remove', task)">
            <v-icon v-show="hover">mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </template>
      <template v-else>
        <v-list-item-content>
          <v-text-field v-model="task.title" @keypress.enter="update" autofocus></v-text-field>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn color="success" icon @click="update">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-list-item-action>
      </template>


    </v-list-item>
  </v-hover>
</template>

<script>
export default {
  name: "EditTask",
  props: {
    value: [Object, Array],
    progression: Number
  },
  data() {
    return {
      task: this.value,
      edit: false
    }
  },
  methods: {
    title: function (task) {
      let str = task.title
      return str
    },
    update: function () {
      this.edit = false
      this.$emit('update', this.task)
    }
  }
}
</script>

<style scoped>
  .item-task {
    /*max-height: 44px;*/
  }
  .line-through {
    text-decoration: line-through;
  }
</style>